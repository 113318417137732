import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import Root from './../components/common/Root'
import Header from './../components/common/Header'
import PrivacyPolicyHero from './../components/page-components/PrivacyPolicyHero'

export default function Home({ location }) {
  return (
    <Root>
      <Helmet>
        <title>Bitcoin Alliance</title>
        <meta name="description" content="Bitcoin Alliance" />
        {/*  */}
      </Helmet>
      <Header
        hero={
          <PrivacyPolicyHero/>
        }
      />
    </Root>
  )
}
