import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import { Location } from '@reach/router'
import { uiConstants } from './../../../utils/ui-constants'
import { breakpoints } from './../../../utils/theme'
import Layout from './../common/Layout'
import HeroForm from './HeroForm'
import HomepageHeroGraphicMobile from './../../images/hero-graphic-mobile.svg'
import WavesBottom from './../../images/waves.gif'
import Cross from './../../images/cross.png'
import BitcoinAllianceDiagram from './../../images/BitcoinAllianceDiagram.png'
import SupportersList from './SupportersList'
import SponsorsList from './SponsorsList'

const HomepageHero = (props) => {
    const [language, setLanguage] = useState(
        props.location.pathname.split("/")[1]
    );
    return (
        <StyledHomepageHero>
            <Layout width="main-width">
                <div className="hero-content" data-aos="fade-up">
                    <SupportersList />
                    <main>

                        <h1>
                            Privacy Notice
                        </h1>
                        <p className="sub-heading">
                            Last updated: October 6, 2021.
                        </p>
                        <h2>
                            GENERAL INFORMATION
                        </h2>
                        <p>
                            The information in this section is relevant to all categories of data
                            subject.
                        </p>
                        <p>
                            This includes:
                        </p>
                        <ul>
                            <li>
                                <p>
                                    <a target="_blank" href="https://api3.org/privacy-policy#staff">
                                        Staff and contractors
                                    </a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <a target="_blank" href="https://api3.org/privacy-policy#website-users">
                                        Website users
                                    </a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <a target="_blank" href="https://api3.org/privacy-policy#suppliers">
                                        Suppliers and Potential suppliers
                                    </a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <a target="_blank" href="https://api3.org/privacy-policy#clients">
                                        Clients and Potential clients
                                    </a>
                                </p>
                            </li>
                        </ul>
                        <h2>
                            Who controls your personal data?
                        </h2>
                        <p>
                            The API3 Foundation, a Cayman Islands foundation company (including our
                            subsidiaries, affiliates, and as governed by the API3 Decentralized
                            Autonomous Organization token holders, “API3”, “we”, “us” or “our”) is
                            responsible for your personal data submitted to bitcoin-alliance.org.
                        </p>
                        <p>
                            You can contact a representative by sending an email to the following
                            address: <a href="mailto:contact@api3.org">contact@api3.org</a>
                        </p>
                        <h2>
                            Your rights
                        </h2>
                        <p>
                            You have the following rights:
                        </p>
                        <ol>
                            <li>
                                <p>
                                    The right to be informed
                                </p>
                            </li>
                        </ol>
                        <p>
                            You have the right to be informed about how API3 processes your personal
                            data. Typically, API3 communicates this information through privacy notices
                            such as this one.
                        </p>
                        <ol start="2">
                            <li>
                                <p>
                                    The right of data access
                                </p>
                            </li>
                        </ol>
                        <p>
                            You have a right to obtain a copy of the personal data we hold about you,
                            subject to certain exceptions.
                        </p>
                        <ol start="3">
                            <li>
                                <p>
                                    The right of data rectification
                                </p>
                            </li>
                        </ol>
                        <p>
                            You always have a right to ask for immediate correction of inaccurate or
                            incomplete personal data which we hold about you.
                        </p>
                        <ol start="4">
                            <li>
                                <p>
                                    The right of data erasure
                                </p>
                            </li>
                        </ol>
                        <p>
                            You have the right to request that personal data be erased when it is no
                            longer needed, where applicable law obliges us to delete the data or the
                            processing of it is unlawful. You may also ask us to erase personal data
                            where you have withdrawn your consent or objected to the data processing.
                            However, this is not a general right to data erasure – there are
                            exceptions.
                        </p>
                        <ol start="5">
                            <li>
                                <p>
                                    The right to restrict data processing
                                </p>
                            </li>
                        </ol>
                        <p>
                            You have the right to restrict the processing of your personal data in
                            specific circumstances. Where that is the case, we may still store your
                            information, but not use it further.
                        </p>
                        <ol start="6">
                            <li>
                                <p>
                                    The right to data portability
                                </p>
                            </li>
                        </ol>
                        <p>
                            You have the right to receive your personal data in a structured,
                            machine-readable format for your own purposes, or to request us to share it
                            with a third party.
                        </p>
                        <ol start="7">
                            <li>
                                <p>
                                    The right to object to data processing
                                </p>
                            </li>
                        </ol>
                        <p>
                            You have the right to object to our processing of your personal data based
                            on the legitimate interests, where your data privacy rights outweigh our
                            reasoning for legitimate interests.
                        </p>
                        <ol start="8">
                            <li>
                                <p>
                                    Rights in relation to automated decision making and profiling
                                </p>
                            </li>
                        </ol>
                        <p>
                            You have the right not to be subjected to a decision based solely on
                            automated processing, including profiling, which produces legal or
                            similarly significant effects. Currently, API3 does not perform any
                            automated decision making or profiling.
                        </p>
                        <p>
                        You may request to enforce your data privacy rights by emailing                                address: <a href="mailto:contact@api3.org">contact@api3.org</a>
                        </p>
                        <p>
                            In certain circumstances, we may need to restrict the above rights to
                            safeguard the public interest (e.g., the prevention or detection of crime)
                            or our business interests (e.g., the maintenance of legal privilege).
                        </p>
                        <h2>
                            Consent as a legal basis for processing
                        </h2>
                        <p>
                            For some data processing, API3 uses consent as a legal basis. If you have
                            consented to processing by API3, please be aware that you have the right to
                            withdraw this consent at any point. If you would like to withdraw consent
                            for a particular type of data processing that API3 performs, please email
                            the following address <a href="mailto:contact@api3.org">contact@api3.org</a>

                        </p>
                        <h2>
                            Complaints to a Supervisory Authority
                        </h2>
                        <p>
                            You have the right to lodge a complaint with a supervisory authority with
                            regards to the way that API3 processes your personal data. API3 recommends
                            lodging a complaint with the data protection authority officer/regulatory
                            authority in the country in which you are a permanent resident.
                        </p>
                        <h2>
                            How we share your data
                        </h2>
                        <p>
                            We will not share your information with any third parties other than the
                            Open Bank Project (aka Tesobe GmbH) for the purposes of direct marketing.
                        </p>
                        <p>
                            In some circumstances we are legally obliged to share information. For
                            example, under a court order. In any scenario, we will satisfy ourselves
                            that we have a lawful basis on which to share the information and document
                            our decision making and satisfy ourselves we have a legal basis on which to
                            share the information.
                        </p>
                        <h2>
                            How we protect your information
                        </h2>
                        <p>
                            We implement appropriate technical and organisational measures to protect
                            personal data that we hold from unauthorised disclosure, use, alteration,
                            or destruction. Where appropriate, we use encryption and other technologies
                            that assist in securing the data you provide. We also require our service
                            providers to comply with strict data privacy requirements where they
                            process your personal data.
                        </p>
                        <h2>
                            How long we keep your personal data
                        </h2>
                        <p>
                            We only keep your personal data for as long as necessary for the purposes
                            described in this privacy notice, or until you notify us that you no longer
                            wish us to process your data. After this time, we will securely delete your
                            personal data, unless we are required to keep it to meet legal or
                            regulatory obligations, or to resolve potential legal disputes.
                        </p>
                        <h2>
                            Contact and further information
                        </h2>
                        <p>
                            If you have any questions about how we use your personal data or wish to
                        make a complaint about how we handle it, you may contact API3 at:    <a href="mailto:contact@api3.org">contact@api3.org</a>
                        </p>
                        <p className="section-heading">
                            MEMBERS OF STAFF AND CONTRACTORS WITH API3
                        </p>
                        <h2>
                            Why we use your personal data?
                        </h2>
                        <p>
                            We may use your personal data as listed above for the following purposes,
                            to the extent applicable:
                        </p>
                        <ul>
                            <li>
                                <p>
                                    human resources management including organization and personal
                                    administration, working hours management, improving and maintaining
                                    effective staff administration, internal workforce analysis,
                                    reporting and planning;
                                </p>
                            </li>
                            <li>
                                <p>
                                    staff succession planning;
                                </p>
                            </li>
                            <li>
                                <p>
                                    compensation and benefits management and performance reviews;
                                </p>
                            </li>
                            <li>
                                <p>
                                    talent management and acquisition including recruitment, assessing
                                    suitability and working capacity, background checks and
                                    verification of qualifications, obtaining and providing references;
                                </p>
                            </li>
                            <li>
                                <p>
                                    learning and development management including certifications,
                                    training staff and performing assessments and employee satisfaction
                                    surveys;
                                </p>
                            </li>
                            <li>
                                <p>
                                    processes related to joining and leaving including internal moves
                                    and terminations;
                                </p>
                            </li>
                            <li>
                                <p>
                                    sickness and other leave and vacations management;
                                </p>
                            </li>
                            <li>
                                <p>
                                    reporting and managing process quality;
                                </p>
                            </li>
                            <li>
                                <p>
                                    travel and expenses management and organization of business trips;
                                </p>
                            </li>
                            <li>
                                <p>
                                    carrying out the obligations and exercising specific rights in the
                                    field of employment or a collective agreement;
                                </p>
                            </li>
                            <li>
                                <p>
                                    internal and external communication of API3’s organization and
                                    representation of API3 including commercial register and assigning
                                    powers of attorney;
                                </p>
                            </li>
                            <li>
                                <p>
                                    organizing API3 events and documentation of such events including
                                    managing and organizing internal non-marketing related campaigns,
                                    events and meetings;
                                </p>
                            </li>
                            <li>
                                <p>
                                    managing API3 assets including pictures and videos depicting
                                    employees or other individuals available for download on the API3
                                    website, etc.;
                                </p>
                            </li>
                            <li>
                                <p>
                                    finance and shared accounting services providing record to report,
                                    order to cash and purchase to pay services;
                                </p>
                            </li>
                            <li>
                                <p>
                                    business reporting, statistics and analytics;
                                </p>
                            </li>
                            <li>
                                <p>
                                    monitoring and auditing compliance of contractors’ and employees’
                                    activities in the workplace with API3’s policies, contractual
                                    obligations and legal requirements including disciplinary actions;
                                </p>
                            </li>
                            <li>
                                <p>
                                    carrying out audits, reviews and regulatory checks to meet
                                    obligations to regulators;
                                </p>
                            </li>
                            <li>
                                <p>
                                    governance, risk and compliance, including compliance with laws,
                                    law enforcement, court and regulatory bodies’ requirements (such as
                                    for the process of verifying the identity of customers, called as
                                    Know Your Customer / Anti Money Laundering monitoring purposes),
                                    customs and global trade compliance, conflict of interest and
                                    security obligations) and prevention, detection, investigation and
                                    remediation of crime and fraud or prohibited activities or to
                                    otherwise protect legal rights and to establish, exercise or defend
                                    legal claims;
                                </p>
                            </li>
                            <li>
                                <p>
                                    managing the customer relationship, processing customer orders and
                                    providing customer support, processing, evaluating and responding
                                    to requests and inquiries;
                                </p>
                            </li>
                            <li>
                                <p>
                                    managing the suppliers, contractors, advisers and other
                                    professional experts including contact interaction, processing and
                                    fulfilling purchases and invoices, and contract lifecycle
                                    management;
                                </p>
                            </li>
                            <li>
                                <p>
                                    making use of work performance and products and for references on
                                    documents, such as drawings, purchase orders, sales orders,
                                    invoices, reports;
                                </p>
                            </li>
                            <li>
                                <p>
                                    maintaining and protecting the security of products, facilities,
                                    services, systems, networks, computers, and information,
                                    preventing, and detecting security threats, fraud or other criminal
                                    or malicious activities, and ensuring business continuity; and
                                </p>
                            </li>
                            <li>
                                <p>
                                    managing IT resources, including infrastructure management
                                    including data back-up, information systems’ support and service
                                    operations for application management, end user support, testing,
                                    maintenance, security (incident response, risk, vulnerability,
                                    breach response), master data and workplace including user accounts
                                    management, software licenses assignment, security and performance
                                    testing and business continuity.
                                </p>
                            </li>
                        </ul>
                        <p>
                            We collect only the personal data from you that we need for the purposes
                            described above.
                        </p>
                        <h2>
                            What happens if you do not provide us with the information we have
                            requested?
                        </h2>
                        <p>
                            Where it concerns processing operations related to your relationship with
                            API3 as a member, contractor or employee (as described above), API3 will
                            not be able to adequately contract with or employ you without certain
                            personal data and you may not be able to exercise your contractor or
                            employee rights if you do not provide the personal data requested. Although
                            we cannot mandate you to share your personal data with us, please note that
                            this then may have consequences which could affect your contractual
                            relationship or employment in a negative manner, such as not being able to
                            exercise your statutory rights or even to continue your contract or
                            employment. Whenever you are asked to provide us with any personal data
                            related to you, we will indicate which personal data is required, and which
                            personal data may be provided voluntarily.
                        </p>
                        <h2>
                            The legal basis we rely on
                        </h2>
                        <p>
                            For the use of your personal data for the purposes described above (in
                            section 4), we rely on the following legal basis, as applicable:
                        </p>
                        <ul>
                            <li>
                                <p>
                                    We process your personal data for the fulfilment of obligations in
                                    your contractor or employment contract with us and similar
                                    collective contractor or employment agreements, or as part of
                                    pre-contractual measures to establish contractor-related or
                                    employment and related contracts
                                </p>
                            </li>
                            <li>
                                <p>
                                    In some cases, we rely on our legitimate interests to process your
                                    personal data insofar as this is not overridden by your own privacy
                                    interests. Such interests may include:
                                </p>
                            </li>
                            <ul>
                                <li>
                                    <p>
                                        monitoring (for example through IT systems), investigating and
                                        ensuring compliance with legal, regulatory, standard and API3
                                        internal requirements and policies;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        prevention of fraud and criminal activity including
                                        investigations of such activity, misuse of API3 assets,
                                        products, and services, and as strictly necessary and
                                        proportionate for ensuring network and information security;
                                        and
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        transmitting personal data within the API3 group for internal
                                        administrative purposes as necessary, for example to provide
                                        centralised services.
                                    </p>
                                </li>
                            </ul>
                            <li>
                                <p>
                                    You may obtain a copy of our assessment regarding our legitimate
                        interest to process your personal data by submitting a request to            <a href="mailto:contact@api3.org">contact@api3.org</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    In some cases, we process your personal data on the basis of
                                    statutory requirements, for example, on the basis of labour law,
                                    allowances, tax or reporting obligations, cooperation obligations
                                    with authorities or statutory retention periods in order to carry
                                    out our contractual responsibilities as a contracting entity or
                                    employer, as applicable;
                                </p>
                            </li>
                            <li>
                                <p>
                                    In exceptional circumstances we may ask your consent at the time of
                                    collecting the personal data, for example photos, communications
                                    materials, and events. If we ask you for consent in order to use
                                    your personal data for a particular purpose, we will remind you
                                    that you are free to withdraw your consent at any time and we will
                                    tell you how you can do this.
                                </p>
                            </li>
                        </ul>
                        <p className="section-heading">
                            WEBSITE USERS
                        </p>
                        <h2>
                            Unsolicited Personal Information
                        </h2>
                        <p>
                            If you send API3 unsolicited personal information, for example a CV, API3
                            reserves the right to immediately delete that information without informing
                            you or to decide which category of data subject that you appear to be and
                            manage your personal data within the remit of that category as described
                            elsewhere in this Privacy Notice. We do not knowingly collect or solicit
                            personal information from anyone under 18 years of age. If you are under
                            18, please do not send any personal information about yourself to us.
                        </p>
                        <p>
                            Users of our website that do not fall under the specifically stated Data
                            Subject Types
                        </p>
                        <h2>
                            The personal data we collect about you and how we get it
                        </h2>
                        <p>
                            We collect the following categories of personal data:
                        </p>
                        <ul>
                            <li>
                                <p>
                                    The business contact information you share with us: name, title,
                                    job title, email address, business address, telephone number,
                                    mobile telephone number, etc.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Information your browser makes available when you visit the API3
                                    website: IP address, the source of your site visit, time spent on
                                    the website or a particular page, links clicked, comments shared,
                                    browser type, date and time of visit, etc.
                                </p>
                            </li>
                        </ul>
                        <h2>
                            What we use your personal data for
                        </h2>
                        <p>
                            We use your personal data to:
                        </p>
                        <ul>
                            <li>
                                <p>
                                    respond to your specific request that you make, for example request
                                    a demonstration, whitepapers, newsletters, or other information.
                                </p>
                            </li>
                            <li>
                                <p>
                                    provide customer support and process, evaluate and respond to
                                    requests and inquiries;
                                </p>
                            </li>
                            <li>
                                <p>
                                    conduct and facilitate customer satisfaction surveys;
                                </p>
                            </li>
                            <li>
                                <p>
                                    conduct marketing and sales activities (including generating leads,
                                    pursuing marketing prospects, performing market research,
                                    determining and managing the effectiveness of our advertising and
                                    marketing campaigns and managing our brand);
                                </p>
                            </li>
                            <li>
                                <p>
                                    send you marketing communications (such as alerts, promotional
                                    materials, newsletters, etc.);
                                </p>
                            </li>
                            <li>
                                <p>
                                    perform data analytics (such as market research, trend analysis,
                                    financial analysis, and customer segmentation).
                                </p>
                            </li>
                        </ul>
                        <p>
                            We only collect the personal data from you that we need for the above
                            purposes. We may also anonymise your personal data, so it no longer
                            identifies you and use it for various purposes, including the improvement
                            of our services and testing our IT systems.
                        </p>
                        <h2>
                            The legal basis on which we use your personal data
                        </h2>
                        <p>
                            We use your personal data for the purposes described in this notice based
                            on one of the following legal bases, as applicable:
                        </p>
                        <ul>
                            <li>
                                <p>
                                    Legitimate interest as by using our website it is understood that
                                    there is potential for you to be a potential customer, user,
                                    contractor, employee, or supplier.
                                </p>
                            </li>
                        </ul>
                        <h2>
                            Copyright Violations
                        </h2>
                        <p>
                            We reserve the right to restrict access to or remove material that we
                            believe in good faith to be copyrighted material and/or illegally copied
                            and/or distributed, and restrict and discontinue service to offenders. If
                            you believe that material or content residing on or accessible through the
                            Services infringes your copyright (or the copyright of someone on behalf of
                            which you are authorized to act), please send a notice of copyright
                        infringement containing the following information to    <a href="mailto:contact@api3.org">contact@api3.org</a>:
                        </p>
                        <ol>
                            <li>
                                <p>
                                    (a) A physical or electronic signature of a person authorized to
                                    act on behalf of the owner of the copyright that has been allegedly
                                    infringed;
                                </p>
                            </li>
                            <li>
                                <p>
                                    (b) Identification of works or materials being infringed;
                                </p>
                            </li>
                        </ol>
                        <p className="section-heading">
                            SUPPLIERS OR POTENTIAL SUPPLIERS
                        </p>
                        <h2>
                            The personal data we collect about you and how we use it
                        </h2>
                        <p>
                            We collect and use personal data that concerns you in connection with the
                            agreements with our suppliers. We may collect the following categories of
                            personal data:
                        </p>
                        <ul>
                            <li>
                                <p>
                                    Identification data and business contact information, you share
                                    with us such as first name, last name, job/position/title,
                                    nationality, business email address, business address, telephone
                                    number, mobile telephone number.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Additional information you provide to us in the course of our
                                    business relations such as data concerning the fulfilment of our
                                    contractual obligations and pre-contractual measures including
                                    correspondence data, offers, tenders, resume/CV, conditions,
                                    contract and order data, invoices, payments, business partner
                                    history, records relating to queries/questions/complaints/orders.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Electronic identification data and information collected by the
                                    communications systems, IT applications and website browser (where
                                    supplier has access or is affected by such systems or applications
                                    and in accordance with the applicable law) such as information
                                    technology usage (system access, IT and internet usage), device
                                    identifier (mobile device ID, PC ID), registration and login
                                    credentials, IP address, login data and log files, Analytics ID,
                                    time and URL, searches, website registration and cookie data, sound
                                    recordings (e.g. voice mail/phone recordings, Skype recordings).
                                </p>
                            </li>
                        </ul>
                        <h2>
                            What we use your personal data for
                        </h2>
                        <p>
                            We may use your personal data as described above for the following
                            purposes:
                        </p>
                        <ul>
                            <li>
                                <p>
                                    supplier and service provider management throughout the supply
                                    chain including contact interaction including tendering,
                                    engagement, processing orders, process and fulfilment of purchases,
                                    administration and management of suppliers, vendors, contractors,
                                    advisers and other professional experts;
                                </p>
                            </li>
                            <li>
                                <p>
                                    paying debts, supplier invoice and payment management, purchasing
                                    of direct and indirect services;
                                </p>
                            </li>
                            <li>
                                <p>
                                    reporting and analytics including market intelligence and
                                    development and improvement of services or products through
                                    assessment and analysis of the information;
                                </p>
                            </li>
                            <li>
                                <p>
                                    management of process quality;
                                </p>
                            </li>
                            <li>
                                <p>
                                    references on documents, such as tenders, purchase orders,
                                    invoices, reports;
                                </p>
                            </li>
                            <li>
                                <p>
                                    contract lifecycle management;
                                </p>
                            </li>
                            <li>
                                <p>
                                    payment collection and insolvency processes;
                                </p>
                            </li>
                            <li>
                                <p>
                                    training suppliers;
                                </p>
                            </li>
                            <li>
                                <p>
                                    finance and shared accounting services, providing record to report
                                    and purchase to pay services;
                                </p>
                            </li>
                            <li>
                                <p>
                                    reorganisation, acquisition and sale of activities, business units
                                    and companies;
                                </p>
                            </li>
                            <li>
                                <p>
                                    monitoring and auditing compliance with API3’s policies,
                                    contractual obligations and legal requirements;
                                </p>
                            </li>
                            <li>
                                <p>
                                    carrying out audits, reviews and regulatory checks to meet
                                    obligations to regulators;
                                </p>
                            </li>
                            <li>
                                <p>
                                    governance, risk and compliance, including due diligence and
                                    anti-money laundering obligations, customs and global trade
                                    compliance and sanctioned party list screening, security, including
                                    prevention, detection of crime and fraud;
                                </p>
                            </li>
                            <li>
                                <p>
                                    maintain and protect the security of products, facilities,
                                    services, systems, networks, computers and information, preventing
                                    and detecting security threats, and fraud or other criminal or
                                    malicious activities; and
                                </p>
                            </li>
                            <li>
                                <p>
                                    manage IT resources, including infrastructure management including
                                    data back-up, information systems’ support and service operations
                                    for application management, end user support, testing, maintenance,
                                    security (incident response, risk, vulnerability, breach response),
                                    user accounts management, software licenses assignment, security
                                    and performance testing and business continuity.
                                </p>
                            </li>
                        </ul>
                        <p>
                            We collect only the personal data from you that we need for the purposes
                            described above. For statistical purposes, improvement of our services and
                            testing of our IT systems we use as much as reasonably possible anonymised
                            data. This means that these data can no longer (in)directly identify you or
                            single you out as an individual.
                        </p>
                        <h2>
                            What happens if you do not provide us with the information we had asked you
                            for or if you ask us to stop processing your information?
                        </h2>
                        <p>
                            Where it concerns processing operations related to the agreements with our
                            suppliers (as described above), API3 will not be able to adequately
                            establish, conduct or terminate a business relationship with you or your
                            company and generally perform the purposes described above without certain
                            personal data. Although we cannot obligate you to share your personal data
                            with us, please note that this then may have consequences which could
                            affect the business relationship in a negative manner, such as not being
                            able to take requested pre-contractual measures to enter into a contract
                            with you or to establish and continue the business relationship you have
                            asked for.
                        </p>
                        <h2>
                            The legal basis on which we use your personal data
                        </h2>
                        <p>
                            We use your personal data for the purposes described in this notice based
                            on one of the following legal bases, as applicable:
                        </p>
                        <p>
                            We may process your personal data for the fulfilment of contractual
                            obligations resulting from contracts with you or your company, or as part
                            of pre-contractual measures we take;
                        </p>
                        <p>
                            In some cases, we rely on our legitimate interests to process your personal
                            data insofar as this is not overridden by your own privacy interests. Such
                            interests may include:
                        </p>
                        <ul>
                            <li>
                                <p>
                                    conduct, management, development and furtherance of our business in
                                    the broadest sense possible including supply of products and
                                    services, performance of agreements and order management with
                                    suppliers, process and fulfilment of purchases, process quality
                                    management and improvement of products or services, analytics and
                                    market intelligence, reduction of default risks in our procurement
                                    processes and reorganization, acquisition and sale of activities,
                                    business divisions and companies;
                                </p>
                            </li>
                            <li>
                                <p>
                                    monitor, investigate and ensure compliance with legal, regulatory,
                                    standard and API3 internal requirements and policies;
                                </p>
                            </li>
                            <li>
                                <p>
                                    prevent fraud and criminal activity including investigations of
                                    such activity, misuse of API3 assets, products and services, and as
                                    strictly necessary and proportionate for ensuring network and
                                    information security; and
                                </p>
                            </li>
                            <li>
                                <p>
                                    transmitting personal data within the API3 group for internal
                                    administrative purposes as necessary for example to provide
                                    centralised services.
                                </p>
                            </li>
                        </ul>
                        <p>
                            In some cases, we process your personal data on the basis of legal
                            obligations and statutory requirements, for example, on the basis of tax or
                            reporting obligations, cooperation obligations with authorities, statutory
                            retention periods or the disclosure of personal data within the scope of
                            official or judicial measures may be required for the purposes of taking
                            evidence, prosecution or enforcement of civil law claims.
                        </p>
                        <p>
                            Regarding personal data concerning criminal convictions and offences, we
                            will only process such data where such processing is permitted by
                            applicable (local) law.
                        </p>
                        <p className="section-heading">
                            CUSTOMERS/CLIENTS OR POTENTIAL CUSTOMERS/CLIENTS
                        </p>
                        <h2>
                            The personal data we collect about you and how we use it
                        </h2>
                        <p>
                            We collect the following categories of personal data:
                        </p>
                        <ul>
                            <li>
                                <p>
                                    The business contact information you share with us: name, title,
                                    job title, email address, business address, telephone number,
                                    mobile telephone number
                                </p>
                            </li>
                            <li>
                                <p>
                                    Additional information you provide to us in the course of our
                                    business relations, such as: interests in API3 services or
                                    products, marketing preferences, registration information provided
                                    at events, fairs, contract or order data, invoices, payments,
                                    business partner history, etc.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Information your browser makes available when you visit the API3
                                    website: IP address, the source of your site visit, time spent on
                                    the website or a particular page, links clicked, comments shared,
                                    browser type, date and time of visit, etc.
                                </p>
                            </li>
                            <li>
                                <p>
                                    To the extent necessary to fulfil our obligations, data obtained
                                    from publicly accessible sources or which are legitimately
                                    transmitted by other third parties
                                </p>
                            </li>
                        </ul>
                        <h2>
                            What we use your personal data for
                        </h2>
                        <p>
                            We use your personal data to:
                        </p>
                        <ul>
                            <li>
                                <p>
                                    process and fulfil orders and keep you informed about the status of
                                    your or your company’s order;
                                </p>
                            </li>
                            <li>
                                <p>
                                    provide and administer our products and services;
                                </p>
                            </li>
                            <li>
                                <p>
                                    provide customer support and process, evaluate and respond to
                                    requests and inquiries;
                                </p>
                            </li>
                            <li>
                                <p>
                                    conduct and facilitate customer satisfaction surveys;
                                </p>
                            </li>
                            <li>
                                <p>
                                    conduct marketing and sales activities (including generating leads,
                                    pursuing marketing prospects, performing market research,
                                    determining and managing the effectiveness of our advertising and
                                    marketing campaigns and managing our brand);
                                </p>
                            </li>
                            <li>
                                <p>
                                    send you marketing communications (such as alerts, promotional
                                    materials, newsletters, etc.);
                                </p>
                            </li>
                            <li>
                                <p>
                                    perform data analytics (such as market research, trend analysis,
                                    financial analysis, and customer segmentation).
                                </p>
                            </li>
                        </ul>
                        <p>
                            We only collect the personal data from you that we need for the above
                            purposes. We may also anonymise your personal data, so it no longer
                            identifies you and use it for various purposes, including the improvement
                            of our services and testing our IT systems.
                        </p>
                        <p>
                            What happens if you do not provide us with the information we had asked you
                            for, or if you ask us to stop processing your information?
                        </p>
                        <p>
                            Certain personal data is necessary to establish, conduct or terminate a
                            business relationship with you. We need you to provide us with the personal
                            data required for the fulfilment of contractual obligations or which we are
                            legally obliged to collect. Without such personal data, we will not be able
                            to establish, execute or terminate a contract with you. Also, we will be
                            unable to take requested pre-contractual measures to enter a contract with
                            you or to establish and continue the business relationship you have asked
                            for.
                        </p>
                        <h2>
                            The legal basis on which we use your personal data
                        </h2>
                        <p>
                            We use your personal data for the purposes described in this notice based
                            on one of the following legal bases, as applicable:
                        </p>
                        <ul>
                            <li>
                                <p>
                                    We may process your personal data for the fulfilment of contractual
                                    obligations resulting from contracts with you or your company, or
                                    as part of pre-contractual measures we have been asked to take;
                                </p>
                            </li>
                            <li>
                                <p>
                                    We may process your personal data on the basis of statutory
                                    requirements, for example, on the basis of tax or reporting
                                    obligations, cooperation obligations with authorities or statutory
                                    retention periods;
                                </p>
                            </li>
                            <li>
                                <p>
                                    We will ask your consent for the activities described in this
                                    privacy notice when required by applicable law, for example when we
                                    process your data for marketing purposes where we don’t have an
                                    existing business relationship with you or your company; or
                                </p>
                            </li>
                            <li>
                                <p>
                                    We will rely on our legitimate interests to process your personal
                                    data within the scope of the business relationship with you or your
                                    company. Our legitimate interests to collect and use the personal
                                    data for this purpose are management and furtherance of our
                                    business. You may obtain a copy of our assessment of why we may
                                    process your personal data for these interests by submitting a
                                    request at <a href="mailto:contact@api3.org">contact@api3.org</a>
                                </p>
                            </li>
                        </ul>
                    </main>
                    <SponsorsList />
                </div>
            </Layout>
        </StyledHomepageHero>
    )
}

const StyledHomepageHero = styled(Layout)`
    position: relative;
    padding: 30px 0 120px;
    iframe {
        width: 600px;
        height: 1000px;
        #root {
            display: none;
        }
    }
    .hero-content {
        padding: 0 0 0 152px;
        position: relative;
        z-index: 1;
        max-width: 880;
        width: 100%;
        main {
            ul, ol {
                padding-left: 60px;
                list-style: unset;
            }
            h1 {
                font-size: 50px;
                font-weight: 700;
                color: black;
                line-height: 60px;
                max-width: 982px;
            }
            h2 {
                font-size: 32px;
                margin-top: 48px;
                line-height: 48px;
                max-width: 680px;
            }
            .section-heading {
                font-size: 40px;
                margin-top: 80px;
                font-weight: 800;
                line-height: 52px;
            }
            .sub-heading {
                max-width: 880px;
                margin-top: 24px;
                font-size: 28px;
                font-weight: 300;
                line-height: 42px;
                color: rgba(28, 51, 84, 1);
            }
            p {
                max-width: 880px;
                margin-top: 24px;
                font-weight: 400;
                font-size: 20px;
                line-height: 30px;
            }
        }
        .hero-block {
            margin-bottom: 38px;
            display: flex;
            align-items: center;
            .logo-list-label {
                color: rgba(2, 26, 59, 1);
                font-weight: 400;
                font-size: 14px;
                margin-right: 26px;
                white-space: nowrap;
            }
            &.upper-block {
                .logo {
                    position: relative;
                    padding: 0 26px;
                    img {
                        max-width: 146px;
                        max-height: 58px;
                    }
                    &:first-child {
                        padding-left: 0;
                    }
                    &:last-child {
                        &:after {
                            display: none;
                        }
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        right: 0;
                        top: calc(50% - 10px);
                        height: 20px;
                        width: 1px;
                        background: linear-gradient(266.75deg, #F9DE32 22.53%, #FBB040 106.6%);
                    }
                }
            }
            &.lower-block {
                margin-top: 64px;
                display: flex;
                .left-logos,
                .right-logos {
                    display: flex;
                    align-items: center;
                    .logo {
                        img {
                            max-width: 126px;
                            max-height: 44px;
                        }
                    }
                }
                .left-logos {
                    padding-right: 42px;
                    position: relative;
                    .cross {
                        margin: 8px;
                        width: 41px;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        right: 0;
                        top: calc(50% - 10px);
                        width: 1px;
                        height: 20px;
                        background: #C6CBD8;
                    }
                }
                .right-logos {
                    padding-left: 42px;
                    .logo {
                        margin-right: 28px;
                    }
                }
            }
        }
    }
    .hero-logos-list {
        display: flex;
        align-items: center;
    }
    #animation_container {
        max-width: 50vw;
        top: -100px;
        right: 0px;
        position: absolute;
    }
    .hero-image {
        display: none;
        position: absolute;
        pointer-events: none;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .hero-image-bottom {
        width: 694px;
        height: 100px;
        position: absolute;
        bottom: 0px;
        left: 0;
        pointer-events: none;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center left;
        background-image: url(${WavesBottom});
        background-blend-mode: multiply;
        mix-blend-mode: multiply;
    }
    @media screen and (max-width: ${breakpoints.xl}) {
        .hero-content {
            padding: 0px;
            padding-top: 28px;
        }
    }
    @media screen and (max-width: ${breakpoints.l}) {
        .hero-content {
            padding: 0px;
            h1 {
                font-size: 50px;
                line-height: 60px;
            }
            .sub-heading {
                margin-top: 24px;
                max-width: 470px;
                span {
                    font-size: 28px;
                    font-weight: 300;
                    line-height: 42px;
                }
            }
            .hero-block {
                &.upper-block {
                    .logo-list-label {
                        margin-right: 8px;
                        font-size: 10px;
                    }
                    .logo {
                        padding: 0 10px !important;
                        margin: 0;
                    }
                }
                &.lower-block {
                    margin-top: 208px;
                }
            }
        }
        #animation_container {
            display: none;
        }
        .hero-image {
            width: 500px;
            height: 500px;
            right: 0px;
            top: 280px;
            display: unset;
            background-position: right center;
            background-image: url(${HomepageHeroGraphicMobile});
        }
        .hero-image-bottom {
            bottom: 260px;
            left: -50px;
        }
    }
    @media screen and (max-width: ${breakpoints.s}) {
        padding: 0;
        .hero-content {
            h1 {
                font-size: 39px;
                line-height: 47px;
            }
            .sub-heading {
                max-width: 470px;
                margin-top: 24px;
            }
            .hero-block {
                &.upper-block {
                    .logo-list-label {
                        margin-right: 8px;
                        font-size: 10px;
                    }
                    .logo {
                        padding: 0 10px !important;
                        margin: 0;
                        img {
                            max-width: 85px;
                            max-height: 30px;
                        }
                    }
                }
                &.lower-block {
                    margin-top: 156px;
                    display: flex;
                    .logo {
                        img {
                            max-height: 32px;
                            max-width: 72px;
                        }
                    }
                    .left-logos {
                        padding-right: 16px;
                        .cross {
                            width: 24px;
                        }
                    }
                    .right-logos {
                        padding-left: 16px;
                            .logo {
                                margin-right: 8px;
                            }
                        }
                    }
                }
            }
            .hero-image {
                width: 300px;
                height: 300px;
                right: 0px;
                top: 280px;
                display: unset;
                background-position: right center;
                background-image: url(${HomepageHeroGraphicMobile});
            }
            .hero-image-bottom {
                bottom: 110px;
                left: -200px;
            }
        }
        @media screen and (max-width: ${breakpoints.s}) {
           .hero-content {
                .sub-heading {
                    span {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
                .hero-block {
                    &.lower-block {
                        .logo {
                            img {
                                max-height: 21px !important;
                                max-width: 73px !important;
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default props => (
    <Location>
        {locationProps => <HomepageHero {...locationProps} {...props} />}
    </Location>
)
